<template>
  <div class="container article">
    <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>HOLOWITS Offering</span></div>
    <h3 class="article-title"><span>Involving from IPC to AIC</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <p class="article-content" style="text-align: center;">Software-defined Architecture, Transforming from "Feature camera" to "Smart camera"</p>
    <div class="image-list">
        <div class="iamge-item">
            <img src="https://resources.holowits.com.sg/about/图片1.png" alt="" style="width: 170px; height: 112px;"/>
            <div class="image-label"><span>1960s Analog camera</span></div>
            <div class="image-details"><span>Optical and electromechanical</span></div>
        </div>
        <div class="iamge-item">
            <img src="https://resources.holowits.com.sg/about/图片2.png" alt="" style="width: 170px; height: 112px;"/>
            <div class="image-label"><span>1990s IP camera</span></div>
            <div class="image-details"><span>One camera for dedicated use</span></div>
        </div>
        <div class="iamge-item">
            <img src="https://resources.holowits.com.sg/about/图片3.png" alt="" style="width: 170px; height: 112px;"/>
            <div class="image-label"><span>2020s AI camera</span></div>
            <div class="image-details"><span>AI-powered Always-on intelligence</span></div>
        </div>
    </div>
    <div class="image-list2">
        <div class="iamge-item2">
            <div class="image-label"><span>Legacy cameras</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></div>
            <div class="image-details"><span>Limited computing power</span></div>
            <img src="https://resources.holowits.com.sg/about/图片4.png" alt="" style="width: 220px; height: 220px;"/>
        </div>
        <div class="iamge-item2">
            <div class="image-label"><span>AI camera</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></div>
            <div class="image-details"><span>&nbsp;</span></div>
            <img src="https://resources.holowits.com.sg/about/图片5.png" alt="" style="width: 220px; height: 220px;"/>
        </div>
    </div>
    <p class="article-content" style="margin-top: 30px;">We firmly contend that the trajectory of AI camera technology bears a striking resemblance to the evolution from feature phones to smartphones. It is our considered opinion that this transformation, characterized by substantial advancements and revolutionary capabilities, is destined to propel the AI camera domain to new heights and fundamentally reshape the landscape of visual imaging.</p>
    <h3 class="article-title"><span>AI ROOT Technologies</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <p class="article-content" style="text-align: center;">What makes HOLOWITS unique in the industry?</p>
    <p class="article-content" style="text-align: center; margin-top: 20px;">In intelligent sensing field, HOLOWITS owns advanced AI root technologies.</p>
    <img src="https://resources.holowits.com.sg/about/图片20.png" alt="" width="100%" style="margin-top: 30px;">

    <h3 class="article-title"><span>Products based on AI ROOT Technologies</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <div class="img-container" style="padding-top: 30px;">
        <div class="title-red-box">
            <span>SuperColor</span>
        </div>
        <div class="img-content">
            <div class="img-content-left">
                <div class="top t-border"><span>Challenges</span></div>
                <div class="bottom t-border">
                    <div class="graph-item">
                        <img src="https://resources.holowits.com.sg/about/图片17.png" alt="" style="width: 50%;"><div>Light compensation is required under low-lit conditions</div>
                    </div>
                    <div class="graph-item">
                        <img src="https://resources.holowits.com.sg/about/图片11.png" alt="" style="width: 50%;"><div>Traditional ISP optimization has reached the bottleneck</div>
                    </div>
                    <div class="graph-item">
                        <img src="https://resources.holowits.com.sg/about/图片12.png" alt="" style="width: 50%;"><div>Interferences caused by rain and dust lead to poor-quality images</div>
                    </div>
                </div>
            </div>
            <div class="img-content-right">
                <div class="top t-border"><span>Crystal-clear night visioin with 0 light pollution</span></div>
                <div class="bottom t-border">
                    <div class="graph-item2">
                        <div style="width: 200px; text-align: center;">
                            <img src="https://resources.holowits.com.sg/about/图片13.png" alt="" style="height: 150px; width: 150px;"/>
                            <div><span>HWT-X6741-20-GZ40-E2-Wp</span></div>
                        </div>
                        <div style="width: 280px; text-align: center;">
                            <img src="https://resources.holowits.com.sg/about/图片19.png" style="width: 100%;" alt="">
                        </div>
                        <div style="width: 100px;">
                            <div style="width: 100%; height: 55px; background-image: url('https://resources.holowits.com.sg/about/图片14.png'); background-size: cover;">
                                <div style="color: white; text-align: center; padding-top: 10px;">Water Scenario</div>
                            </div>
                            <div style="width: 100%; height: 55px; background-image: url('https://resources.holowits.com.sg/about/图片15.png'); background-size: cover;">
                                <div style="color: white; text-align: center; padding-top: 10px;">Campus scenario</div>
                            </div>
                            <div style="width: 100%; height: 55px; background-image: url('https://resources.holowits.com.sg/about/图片16.png'); background-size: cover;">
                                <div style="color: white; text-align: center; padding-top: 10px;">Square High Point</div>
                            </div>
                        </div>
                    </div>
                    <div class="graph-item2">
                        <div style="width: calc(100% / 3);"><video src="https://resources.holowits.com.sg/about/video1.mp4" :controls="true" style="width: 100%;" @mouseenter="enterAction('c1')" @mouseleave="leaveAction('c1')"></video><div style="text-align: center;">Captured onsite by a mobile phone</div></div>
                        <div style="width: calc(100% / 3);"><video src="https://resources.holowits.com.sg/about/video2.mp4" :controls="true" style="width: 100%;" @mouseenter="enterAction('c2')" @mouseleave="leaveAction('c1')"></video><div style="text-align: center;">HOLOWITS</div></div>
                        <div style="width: calc(100% / 3);"><video src="https://resources.holowits.com.sg/about/video3.mp4" :controls="true" style="width: 100%;" @mouseenter="enterAction('c3')" @mouseleave="leaveAction('c1')"></video><div style="text-align: center;">Industry</div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding-top: 90px;">
        <div class="title-red-box">
            <span>SuperCoding</span>
        </div>
        <div style="margin-top: 40px;">
            <div class="coding-top" style="border-radius: 20px; border: 1px solid rgb(199 0 11); padding: 10px;">
                <img src="https://resources.holowits.com.sg/about/supercoding.png" alt="" style="width: 100%;">
            </div>
        </div>
        <div style="margin-top: 15px;">
            <div class="coding-video-label" style="width: 800px; display: flex;">
                <div class="left-label" style="background-color: aliceblue; text-align: center; width: 450px; height: 30px; line-height: 30px; font-weight: 600;">
                    Before
                </div>
                <div class="right-label" style="background-color: rgb(199 0 11); text-align: center; width: 450px; height: 30px; line-height: 30px; font-weight: 600;">
                    After
                </div>
            </div>
            <div style="display: flex;">
                <div style="width: 800px;">
                    <video src="https://resources.holowits.com.sg/about/before after.mp4" :controls="true" style="width: 100%;"></video>
                    <p style="margin-top: 10px; font-weight: 600; font-size: 13px;">1. Pixel-level refined segmentation, from rectangle cropping to contour-level segmentation, saving space and resources.</p>
                    <p style="margin-top: 10px; font-weight: 600; font-size: 13px;">2. Supports the H.264/265 international standards. The frame rate, resolution, and intelligent recognition remain unchanged.</p>
                </div>
                <div style="width: calc(100% - 800px);">
                    <img src="https://resources.holowits.com.sg/about/图片21.png" alt="" style="width: 30px; height: 291.78px;"/>
                    <div style="display: inline-block; margin-left: 20px; vertical-align: middle;">
                        <div style="font-size: 18px; font-weight: 600;">Effect</div>
                        <p><span style="font-size: 16px; font-weight: 600;">Industry H.265</span><img src="https://resources.holowits.com.sg/about/图片22.png" alt=""><span style="font-size: 16px; font-weight: 600;">50%</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-top: 90px;">
        <div class="title-red-box">
            <span>SuperAI Performance</span>
        </div>
        <div style="display: flex; align-items: center; margin-top: 50px;">
            <div style="width: 250px; height: 250px; background-image: url('https://resources.holowits.com.sg/about/performance_1.png'); background-size: cover;">
                <div style="width: 100%; height: 100%; color: #000000; font-size: 16px; text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <div style="font-size: 30px; font-weight: 600;">HOLOWITS</div>
                    <div style="font-size: 30px; font-weight: 600; margin-top: -10px;">SuperAI</div>
                    <div style="font-weight: 600;">1Tops = Industry AI 2Tops</div>
                </div>
            </div>
            <div style="width: 200px; height: 250px; color: #000000; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <div style="font-size: 24px; font-weight: 600;">HOLOWITS</div>
                    <div style="font-size: 24px; font-weight: 600; margin-top: -10px;">AI Architecture</div>
            </div>
            <div style="width: 384px;">
                <div style="height: 120px; position: relative;">
                    <img src="https://resources.holowits.com.sg/about/performance_2.png" style="width: 100%; height: 120px;" alt="">
                    <div style="position: absolute; left: 0; top: 0; height: 100%; display: flex; align-items: center;">
                        <div style="padding: 0 15px;">
                            <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI model conversion tool</p>
                            <p style="padding-top: 5px; font-weight: 600; text-align: center;">Compatible with mainstream models and adaptable to device-edge-cloud products</p>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px; height: 120px; position: relative;">
                    <img src="https://resources.holowits.com.sg/about/performance_2.png" style="width: 100%; height: 120px;" alt="">
                    <div style="position: absolute; left: 0; top: 0; height: 100%; display: flex; align-items: center;">
                        <div style="padding: 0 15px;">
                            <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI development framework</p>
                            <p style="padding-top: 5px; font-weight: 600; text-align: center;">Atomic capability standardization and on-demand algorithm orchestration and combination</p>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px; height: 120px; position: relative;">
                    <img src="https://resources.holowits.com.sg/about/performance_2.png" style="width: 100%; height: 120px;" alt="">
                    <div style="position: absolute; left: 0; top: 0; height: 100%; display: flex; align-items: center;">
                        <div style="padding: 0 15px;">
                            <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI deployment framework</p>
                            <p style="padding: 0 10px; padding-top: 5px; font-weight: 600; text-align: center;">Algorithm aggregation on the cloud and on-demand algorithm loading</p>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px; height: 120px; position: relative;">
                    <img src="https://resources.holowits.com.sg/about/performance_2.png" style="width: 100%; height: 120px;" alt="">
                    <div style="position: absolute; left: 0; top: 0; height: 100%; display: flex; align-items: center;">
                        <div style="padding: 0 15px;">
                            <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI inference framework</p>
                            <p style="padding-top: 5px; font-weight: 600; text-align: center;">Compatible with heterogeneous computing power and intelligent acceleration of software and hardware</p>
                        </div>
                    </div>
                </div>
                <!-- <div style="height: 97px; background-image: url('https://resources.holowits.com.sg/about/performance_2.png'); background-size: cover; padding: 10px 12px;">
                    <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI model conversion tool</p>
                    <p style="padding-top: 5px; font-weight: 600; text-align: center;">Compatible with mainstream models and adaptable to device-edge-cloud products</p>
                </div>
                <div style="margin-top: 20px; height: 97px; background-image: url('https://resources.holowits.com.sg/about/performance_2.png'); background-size: cover; padding: 10px 8px;">
                    <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI development framework</p>
                    <p style="padding-top: 5px; font-weight: 600; text-align: center;">Atomic capability standardization and on-demand algorithm orchestration and combination</p>
                </div>
                <div style="margin-top: 20px; height: 97px; background-image: url('https://resources.holowits.com.sg/about/performance_2.png'); background-size: cover; padding: 10px 12px;">
                    <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI deployment framework</p>
                    <p style="padding: 0 10px; padding-top: 5px; font-weight: 600; text-align: center;">Algorithm aggregation on the cloud and on-demand algorithm loading</p>
                </div>
                <div style="margin-top: 20px; height: 97px; background-image: url('https://resources.holowits.com.sg/about/performance_2.png'); background-size: cover; padding: 10px 12px;">
                    <p style="padding: 0 10px; font-size: 16px; font-weight: 600; text-align: center;">HOLOWITS AI inference framework</p>
                    <p style="font-size: 13px; font-weight: 600; text-align: center;">Compatible with heterogeneous computing power and intelligent acceleration of software and hardware</p>
                </div> -->
            </div>
            <div style="width: 200px; margin-left: 60px;">
                <div style="height: 60px; background-image: url('https://resources.holowits.com.sg/about/performance_3.png'); background-size: cover; font-size: 18px; font-weight: 600; display: flex; align-items: center; justify-content: center;">
                    Model conversion
                </div>
                <div style="text-align: center; margin: 10px 0;"><img src="https://resources.holowits.com.sg/about/performance_4.png" alt="" width="50px"/></div>
                <div style="height: 60px; background-image: url('https://resources.holowits.com.sg/about/performance_3.png'); background-size: cover; font-size: 16px; font-weight: 600; display: flex; align-items: center; justify-content: center; text-align: center;">
                    Algorithm <br/>package generation
                </div>
                <div style="text-align: center; margin: 10px 0;"><img src="https://resources.holowits.com.sg/about/performance_4.png" alt="" width="50px"/></div>
                <div style="height: 60px; background-image: url('https://resources.holowits.com.sg/about/performance_3.png'); background-size: cover; font-size: 15px; font-weight: 600; display: flex; align-items: center; justify-content: center; text-align: center;">
                    On-demand algorithm deployment
                </div>
                <div style="text-align: center; margin: 10px 0;"><img src="https://resources.holowits.com.sg/about/performance_4.png" alt="" width="50px"/></div>
                <div style="height: 60px; background-image: url('https://resources.holowits.com.sg/about/performance_3.png'); background-size: cover; font-size: 18px; font-weight: 600; display: flex; align-items: center; justify-content: center;">
                    Efficient inference
                </div>
            </div>
        </div>
    </div>

    <h3 class="article-title"><span>Product Architecture</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <div style="padding-top: 50px;">
        <div class="title-red-box">
            <span>HOLOWITS Cloud Service</span>
        </div>
        <img src="https://resources.holowits.com.sg/about/product%20art1.jpg" alt="" width="100%">
    </div>
    <div style="padding-top: 50px;">
        <div class="title-red-box">
            <span>HOLOWITS Video Intelligent Platform & NVR/XVR</span>
        </div>
        <img src="https://resources.holowits.com.sg/about/product%20art2.jpg" alt="" width="100%">
    </div>
    <div style="padding-top: 50px;">
        <div class="title-red-box">
            <span>HOLOWITS AI Camera & IP/Analog Camera</span>
        </div>
        <img src="https://resources.holowits.com.sg/about/product%20art3.jpg" alt="" width="100%">
    </div>


    <h3 class="article-title"><span>Solution</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <div class="solutions">
        <div class="solution-item">
            <div class="solution-item-label">
                <div>
                    <div>Intelligent</div>
                    <div>Transportation</div>
                </div>
            </div>
            <div class="image-link">
                <a href="https://www.holowits.com/solution-details/9/solution" target="_blank"><img src="https://resources.holowits.com.sg/about/图片23.png" alt=""/></a>
                <div class="tips">Learn More <i class="el-icon-arrow-right" style="color: rgb(199 0 11);"></i></div>
            </div>
        </div>
        <div class="solution-item">
            <div class="solution-item-label">
                <div>
                    <div>Intelligent</div>
                    <div>City</div>
                </div>
            </div>
            <div class="image-link">
                <a href="https://www.holowits.com/solution-details/10/solution" target="_blank"><img src="https://resources.holowits.com.sg/about/图片24.png" alt=""/></a>
                <div class="tips">Learn More <i class="el-icon-arrow-right" style="color: rgb(199 0 11);"></i></div>
            </div>
        </div>
        <div class="solution-item">
            <div class="solution-item-label">
                <div>
                    <div>Intelligent</div>
                    <div>Campus</div>
                </div>
            </div>
            <div class="image-link">
                <a href="https://www.holowits.com/solution-details/7/solution" target="_blank"><img src="https://resources.holowits.com.sg/about/图片25.png" alt=""/></a>
                <div class="tips">Learn More <i class="el-icon-arrow-right" style="color: rgb(199 0 11);"></i></div>
            </div>
        </div>
        <div class="solution-item">
            <div class="solution-item-label">
                <div>
                    <div>Intelligent</div>
                    <div>Retail</div>
                </div>
            </div>
            <div class="image-link">
                <a href="https://www.holowits.com/solution-details/4/solution" target="_blank"><img src="https://resources.holowits.com.sg/about/图片26.png" alt=""/></a>
                <div class="tips">Learn More <i class="el-icon-arrow-right" style="color: rgb(199 0 11);"></i></div>
            </div>
        </div>
    </div>
    <p class="article-content" style="margin-top: 40px; padding: 0 40px;">With AI cameras and platform, HOLOWITS cooperates with partners to develop solutions of intelligent transportation, city, campus and retail for various customers, which helps achieve efficient management, service innovation, and business success. HOLOWITS continuously delivers products and services that help us build a world where all things can sense, making work and life easier and smarter. </p>
  </div>
</template>

<script>
export default {
name: 'about2',
data() {
    return {
        controls: {
            c1: false,
            c2: false,
            c3: false,
        }
    }
},
methods: {
    enterAction(target) {
        this.controls[target] = true;
    },
    leaveAction(target) {
        this.controls[target] = false;
    },
}
}
</script>

<style lang="less" scoped>
.solutions {
    display: flex;
    justify-content: space-between;
    .solution-item {
        width: 24%;
        .solution-item-label {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            padding-left: 20px;
            padding-right: 20px;
            & > div {
                padding-bottom: 10px;
                border-bottom: 2px solid rgb(199 0 11);
            }
        }
        .image-link {
            position: relative;
            overflow: hidden;
            .tips {
                position: absolute;
                height: 60px;
                text-align: center;
                line-height: 60px;
                font-size: 20px;
                font-family: 'Arial';
                color: white;
                width: 100%;
                bottom: -60px;
                left: 0;
                transition: all 0.2s;
                background-color: rgba(235, 235, 235, 0.24);
                z-index: 2;
                /deep/i[class^=el-icon-] {
                    font-weight: 600 !important;
                }
            }
            &:hover {
                .tips {
                    bottom: 0px;
                }
            }
        }
        img {
            margin-top: 20px;
            width: 100%;
            height: 470px;
        }
    }
}
.img-content {
    margin-top: 40px;
    display: flex;
    .t-border {
        border: 2px solid;
        border-image: linear-gradient(to left, rgba(199, 0, 11, 0.3), rgba(199, 0, 11, 0.1), transparent, rgba(199, 0, 11, 0.1), rgba(199, 0, 11, 0.3)) 2;
    }
    .img-content-left {
        width: 30%;
        // flex-shrink: 0;
    }
    .img-content-right {
        width: 70%;
        // flex-shrink: 0;
        margin-left: 10px;
    }
    .top {
        text-align: center;
        padding: 8px 0;
        font-size: 18px;
        font-weight: 600;
    }
    .bottom {
        margin-top: 10px;
        .graph-item {
            display: flex;
            align-items: center;
            font-weight: 600;
            margin-bottom: 26.5px;
        }
        .graph-item2 {
            height: 190px;
            &:nth-child(1) {
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
            &:nth-child(2) {
                display: flex;
                justify-content: space-around;
                align-items: stretch;
            }
        }
    }
}
.title-red-box {
    text-align: center;
    span {
        display: inline-block;
        // width: 400px;
        padding: 0 30px;
        height: 36px;
        font-size: 22px;
        line-height: 36px;
        color: white;
        text-align: center;
        margin: 0 auto;
        background-color: rgb(199 0 11);
        position: relative;
        &::before {
            content: '';
            width: 10px;
            height: 36px;
            background-color: rgb(199 0 11);
            position: absolute;
            left: -20px;
            top: 0;
        }
        &::after {
            content: '';
            width: 10px;
            height: 36px;
            background-color: rgb(199 0 11);
            position: absolute;
            right: -20px;
            top: 0;
        }
    }
}
.article {
    padding: 20px 10px;

    .image-list {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .iamge-item {
            text-align: center;
            position: relative;
            .image-label {
                font-size: 16px;
                font-weight: 600;
            }
            &:not(:nth-child(3n)) {
                &::before {
                    content: '';
                    background-image: url('https://resources.holowits.com.sg/about/图片6.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 33px;
                    height: 25px;
                    position: absolute;
                    top: calc(56px - 10px);
                    right: -157px;
                }
            }
        }
        
    }

    .image-list2 {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .iamge-item2 {
            text-align: center;
            position: relative;
            .image-label {
                font-size: 16px;
                font-weight: 600;
                span {
                    display: block;
                }
            }
            .image-details {
                margin-top: 10px;
            }
            img {
                margin-top: 10px;
            }
        }

        .iamge-item2 + .iamge-item2 {
            &::before {
                content: '';
                background-image: url('https://resources.holowits.com.sg/about/图片7.png');
                background-size: cover;
                background-repeat: no-repeat;
                width: 117px;
                height: 164px;
                position: absolute;
                top: calc(164px / 2);
                left: -250px;
            }
        }
    }

    .article-title {
    font-size: 40px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    padding: 100px 0 40px;
    span {
        // border-bottom: 2px solid rgb(199 0 11);
        display: block;
        font-size: 28px;
        font-weight: 600;
    }
    }

    .article-content {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 2.0;
    }
}
@media (max-width: 1200px) {
    .coding-video-label {
        width: 100% !important;

        div {
            width: 50%;
        }
    }
}
</style>