<template>
    <!-- v-loading="pageLoading" element-loading-text="Loading..." -->
    <div class="page">
      <!-- <img class="section-figure" v-for="(item,index) in bannerList" :key="index" :src="domainUrl+item.bannerUrl" alt=""></img> -->
      <!-- <div class="section-figure" v-for="(item,index) in bannerList" :key="index" :style="{'background-image': `url(${domainUrl+item.bannerUrl})`}"></div> -->
      <div class="container article">
        <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Who is HOLOWITS</span></div>
        <h3 class="article-title"><span>Our Vision</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
        <div style="text-align: center; font-size: 21px; font-weight: 600; margin-bottom: 20px;"><span>Everything sensing for an intelligent world</span></div>
        <!-- {{$i18n.t('aboutHWT.title')}} -->
        <h3 class="article-title"><span>About US</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
        <!-- {{$i18n.t('aboutHWT.desc')}} -->
        <p class="article-content">HOLOWITS is a global provider of intelligent Internet of Things (AIoT) products and solutions based on multi-dimensional awareness technologies, and integrates a broad range of capabilities such as R&D, production, distribution, sales and service capabilities. HOLOWITS is endowed with intelligent sensing technologies, a full-fledged industry chain, and powerful technical support capabilities. Responding to the ever-growing global demand for intelligence, HOLOWITS is committed to developing AI Camera (AIC), Video Intelligent Platform (VIP), as well as cloud services. These offerings can be applied in a wide variety of scenarios such as intelligent city, intelligent transportation, intelligent energy, intelligent manufacturing, intelligent campus, and intelligent retail. We aim to work together to build an intelligent world with all things sensing. To achieve this, we are devoted to providing safe, reliable, easy-to-use, and outstanding products and solutions for the global market.</p>
      </div>
      <section class="video-section" style="padding-bottom: 50px;">
        <div class="container">
          <div class="video-content">
            <video-player ref="videoPlayer" class=" vjs-default-skin vjs-big-play-centered" playsinline :options="playerOptions"/>
          </div>
        </div>
      </section>
      <!-- <div class="container article">
        <h3 class="article-title"><span>Company Structure</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
        <img src="https://resources.holowits.com.sg/about/company profile.png" style="width: 100%;" alt="">
        <p class="article-content">Our organization is designed to support the rapid iteration of our products and to provide unwavering support to our sales partners. We have a dedicated R&D team that works tirelessly to innovate and improve our product offerings. Our sales and customer service teams work closely with our partners to ensure they have the resources and support they need to succeed.<br/>Our commitment to continuous improvement and partner support is what sets us apart in the industry. We are dedicated to providing high-quality, innovative products and exceptional service to our customers and partners worldwide.</p>
      </div> -->
    </div>
  </template>
  
  <script>
    import {videoPlayer} from 'vue-video-player';
    import 'video.js/dist/video-js.css';
    import {listBannerByPosition} from "@/api/bannerApi";
  
    export default {
      name: 'about',
      components: {
        videoPlayer,
      },
      data() {
        return {
          pageLoading: true,
          bannerList: [],
          playerOptions: {
            playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: true, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                type: 'video/mp4',
                // src: 'https://resources.holowits.com.sg/material/570c69b4b4f64f7e86ab2f9d43814e71.mp4', //视频url地址
                src: 'https://resources.holowits.com.sg/about/HOLOWITS Brand Video-Everything Sensing for an Int.mp4', //视频url地址
              }],
            poster: require('@/assets/image/common/video_cover.png'), //你的封面地址
            // width: document.documentElement.clientWidth,
            notSupportedMessage: `This video can't be played right now. Please try again later`, //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: false,
              fullscreenToggle: true, //全屏按钮
            },
          },
        };
      },
      mounted() {
        // this.init()
        // this.pageLoading = false
      },
      methods:{
        init() {
          listBannerByPosition({showPosition: 'about-header'}).then(response => {
            setTimeout(() => {
              this.pageLoading = false;
            }, 1000);
            this.bannerList = response.data.data;
          });
        },
      },
    };
  </script>
  
  <style scoped lang="less">
    .banner {
      display: block;
      pointer-events: none;
      width: 100%;
    }
    .section-figure {
      width: 100%;
      height: 450px;
    }
    .article {
      padding: 20px 10px;
  
      .article-title {
        font-size: 40px;
        font-weight: 400;
        color: #000000;
        text-align: center;
        padding: 50px 0 40px;
        span {
            // border-bottom: 2px solid rgb(199 0 11);
            display: block;
            font-size: 28px;
            font-weight: 600;
        }
      }
  
      .article-content {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 2.0;
      }
    }
  
    @media (max-width: 1400px) {
      .video-content {
        padding: 0 !important;
      }
    }
  
    .video-section {
    //   padding: 40px 94px 94px;
      padding-top: 5px;
      .video-content {
        padding: 0 160px;
      }
  
      /deep/ .vjs-big-play-button {
        background-image: url(../../../assets/image/activity/minisite/play.png);
        background-size: 100%;
        width: 86px;
        height: 86px;
        border: none;
        // border-radius: 50%;
        background-color: transparent;
  
        span {
          display: none;
        }
      }
  
      /deep/ .vjs-poster {
        background-size: 100% 100%;
        background-color: transparent;
      }
  
      /deep/ .video-js {
        // border-radius: 16px;
        overflow: hidden;
        mask-image: radial-gradient(circle, white 100%, black 100%);
        transform: rotate(0.000001deg);
      }
    }
    @media (min-width: 1200px) {
      // .container {
      //   width: 1400px;
      // }
      .section-figure {
          height: 300px;
          background-size: 100% auto;
          background-position: center;
          background-repeat: no-repeat;
          color: #FFFFFF;
      }
    }
  
    // @media (min-width: 750px) {
    //   .container {
    //     width: 750px;
    //   }
    // }
    @media (max-width: 750px) {
      .page {
        .container {
          width: 100%;
        }
        .section-figure {
          height: 80px;
          background-size: 100% auto;
          background-position: center;
          background-repeat: no-repeat;
          color: #FFFFFF;
        }
        .video-section {
          padding: 0px;
        }
      }
    }
  </style>
  