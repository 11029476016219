import axiosApi from '@/api/axiosApi';

/**
 * 根据位置获取banner
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export const listBannerByPosition = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/web/banner/listByParam',
    data,
});