<template>
  <div class="container article">
    <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Culture & Value</span></div>
    <img src="https://resources.holowits.com.sg/about/IST_24519_67174.png" alt="" style="width: 100%; margin-top: 50px;">
    <p class="text article-content">HOLOWITS strive to create a diverse and inclusive environment, as we firmly believe that creativity and innovation are fostered by embracing diversity. </p>
    <p class="text article-content">We welcome new perspectives and are committed to providing opportunities and support to individuals who embrace new challenges and strive to innovate. </p>
    <p class="text article-content">Working at HOLOWITS means being part of a dynamic team, collaborating closely to create and utilize cutting-edge technology. It is also a place for personal and professional growth, where you'll have ample opportunities to develop both as a skilled professional and as an individual.</p>
    <img src="https://resources.holowits.com.sg/about/价值观.png" alt="" style="width: 100%; margin-top: 100px;">
  </div>
</template>

<script>
export default {
name: 'CultureAndValue',
data() {
    return {}
}
}
</script>

<style lang="less" scoped>
.text {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}
.article {
    padding: 20px 10px;

    .article-title {
    font-size: 40px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    padding: 50px 0 40px;
    span {
        // border-bottom: 2px solid rgb(199 0 11);
        display: block;
        font-size: 28px;
        font-weight: 600;
    }
    }

    .article-content {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 2.0;
    }
}
</style>