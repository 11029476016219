<template>
    <div class="container article">
      <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Data Security</span></div>
      <p class="text article-content">At HOLOWITS, safeguarding your data isn't just a responsibility; it's our foremost commitment. Here's a deep dive into our comprehensive approach towards ensuring data security:</p>
      <ul>
        <li><p class="text article-content">Global Cloud Service Infrastructure: Our data centers aren't confined to a single region. We have established five primary cloud nodes across the world in Singapore, the UAE, Brazil, South Africa, and Ireland. This global presence doesn't just improve accessibility and efficiency; it also ensures data redundancy and recovery in the unforeseen event of a localized catastrophe.</p></li>
        <li><p class="text article-content">Designed for Protection: Our data centers are architectural masterpieces emphasizing user data protection. Each facility incorporates the latest advancements in physical security, environmental controls, and disaster preparedness. This solid foundation, coupled with our innovative software measures, makes our infrastructure one of the most secure in the industry.</p></li>
        <li><p class="text article-content">Legal Compliance and Beyond: While we strictly adhere to the laws and regulations of every country where our nodes are situated, we don't stop there. Our commitment to data security and privacy often goes above and beyond mere legal compliance. It's about earning and keeping our users' trust.</p></li>
        <li><p class="text article-content">Advanced Encryption Protocols: Encryption isn't an afterthought for us; it's a standard. We employ cutting-edge encryption methods, ensuring sensitive data remains shielded from unauthorized prying eyes, whether it's in transit or at rest.</p></li>
        <li><p class="text article-content">Proactive Threat Detection and Response: At HOLOWITS, we believe that the best defense is a good offense. Hence, our systems are constantly on the lookout for anomalies or potential threats. With our advanced threat detection capabilities, we're not just waiting to respond; we're actively identifying and neutralizing threats before they can manifest.</p></li>
        <li><p class="text article-content">24/7 Security Surveillance: Every minute of every day, our key data areas are under rigorous surveillance. Any unusual activity triggers our robust incident response protocol, guaranteeing swift action and minimizing potential impact.</p></li>
        <li><p class="text article-content">Continuous Improvement: In the dynamic world of cyber threats, resting on one's laurels is not an option. We're committed to an ongoing journey of enhancing our systems, processes, and techniques. Regular audits, penetration testing, and training sessions ensure that HOLOWITS remains at the pinnacle of data protection technology.</p></li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
  name: 'CultureAndValue',
  data() {
      return {}
  }
  }
  </script>
  
  <style lang="less" scoped>
  li {
    list-style: disc;
  }
  .text {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
  }
  .article {
      padding: 20px 10px;
      padding-bottom: 150px;
  
      .article-title {
      font-size: 40px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding: 50px 0 40px;
      span {
          // border-bottom: 2px solid rgb(199 0 11);
          display: block;
          font-size: 28px;
          font-weight: 600;
      }
      }
  
      .article-content {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 2.0;
      }
  }
  </style>