<template>
  <div class="container article">
    <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Business Overview</span></div>
    <h3 class="article-title"><span>Business Map</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <img src="https://resources.holowits.com/about/国包分布.png" alt="" style="width: 100%;">
    <p class="text article-content">At HOLOWITS, we understand the importance of a strong and efficient distribution network. Our global reach is a testament to our commitment to making our innovative products accessible to customers around the world.</p>
    <p class="text article-content">Our distribution network spans across continents, with distributors strategically located in key markets worldwide. This extensive network allows us to deliver our products promptly and efficiently, ensuring that our customers receive the best service possible.</p>
    <h3 class="article-title"><span>Partners Wall</span><span style="width: 100px; height: 3px; background-color: rgb(199 0 11); margin: 0 auto; margin-top: 15px;"></span></h3>
    <img src="https://resources.holowits.com.sg/about/partner-wall20240729.png" alt="" style="width: 100%;">
    <p class="text article-content">HOLOWITS continuously working to expand and strengthen our distribution network. We are committed to reaching every corner of the globe, bringing our innovative, sustainable, and energy-efficient solutions to customers everywhere.
Whether you are a potential distributor interested in partnering with us, or a customer looking for our products, we invite you to join us in our journey towards creating a more intelligent and sustainable world.</p>
  </div>
</template>

<script>
export default {
    name: 'BusinessOverview',
    data() {
        return {}
    }
}
</script>

<style  lang="less" scoped>
.text {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}
.article {
    padding: 20px 10px;

    .article-title {
    font-size: 40px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    padding: 100px 0 40px;
    span {
        // border-bottom: 2px solid rgb(199 0 11);
        display: block;
        font-size: 28px;
        font-weight: 600;
    }
    }

    .article-content {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 2.0;
    }
}
</style>