<template>
    <div class="container article">
      <div style="text-align: center; font-size: 35px; color: rgb(199 0 11); font-weight: 600; padding-top: 20px;"><span>Sustainable Development</span></div>
      <p class="text article-content">At HOLOWITS, we are committed to sustainable development, a principle that is deeply ingrained in our business strategy and technological innovations. We believe in creating solutions that not only cater to the needs of our customers but also contribute positively to the environment.<br/>Our commitment to sustainability is reflected in our pioneering use of Artificial Intelligence (AI). We have harnessed the power of AI to develop products that are not only innovative but also environmentally friendly. Our core AI technologies - SuperColor, SuperCoding, SuperAI - are prime examples of this commitment.</p>
      <p class="text article-content">
        SuperColor technology allows our AI cameras to capture vibrant, full-color images without the need for additional illumination. This innovative approach reduces light pollution and conserves energy, contributing to a greener and more sustainable environment.
        <br/>SuperCoding, another key technology, optimizes video transmission and storage. It separates the video into foreground and background, applying normal coding to the dynamic foreground and depth coding to the background. This process reduces transmission bandwidth and storage needs by approximately 60%, resulting in significant energy savings.
        <br/>With SuperAI, Our AI camera's 1 TOPS computing power is equivalent to 2 TOPS in the industry, saving resources. At the same time, our open algorithm ecosystem allows the camera to freely switch algorithms. One AI camera can meet the demands that would otherwise require multiple cameras in the industry.
      </p>
      <p class="text article-content">From a company perspective, we aim to create solutions that are green and energy-efficient. Our Eco-Cube camera, for example, does not require network or power cables, reducing material usage and energy consumption. Furthermore, its design eliminates the need for extra human resources for device maintenance, further reducing our environmental footprint.</p>
      <p class="text article-content">At HOLOWITS, sustainable development is more than just a buzzword; it’s a guiding principle that shapes our business decisions and drives our innovation. We are dedicated to creating a future where technology and sustainability go hand in hand, contributing to a healthier planet and a brighter future for all.</p>
      <img src="https://resources.holowits.com.sg/about/IST_24519_20336.png" alt="" style="width: 100%; margin-top: 50px; padding: 0 100px;">
    </div>
  </template>
  
  <script>
  export default {
  name: 'CultureAndValue',
  data() {
      return {}
  }
  }
  </script>
  
  <style lang="less" scoped>
  .text {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
  }
  .article {
      padding: 20px 10px;
  
      .article-title {
      font-size: 40px;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding: 50px 0 40px;
      span {
          // border-bottom: 2px solid rgb(199 0 11);
          display: block;
          font-size: 28px;
          font-weight: 600;
      }
      }
  
      .article-content {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 2.0;
      }
  }
  </style>